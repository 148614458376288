// React
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
// CSS
import './landingPage.css';

export const LandingPage = (props: any) => {
	const language: string = useSelector((state: any) => state.user.language);

	useEffect(() => {
		let homeElem = document.getElementsByClassName('home');
		if (homeElem) {
			for (let elem of homeElem) {
				(elem as HTMLElement).style.pointerEvents = 'all';
			}
		}

		const scrollManagment = (e?: Event) => {
			let elem = document.getElementById('landingPage-container');
			let elemOpacity = document.getElementById('landingPage-textContainer');
			if (elem && elemOpacity) {
				let { scrollHeight, scrollTop, clientHeight } = elem;

				let max = scrollHeight - clientHeight;
				let percent = ((scrollHeight - scrollTop - clientHeight) * 100) / max;

				if (percent < 0) {
					percent = 0;
				}

				elemOpacity.style.setProperty('--maskHeight', 100 - percent + '%');

				let elemArrow = document.getElementById('landingPage-textScroll');
				if (elemArrow) {
					if (scrollHeight - scrollTop - 15 > clientHeight) {
						elemArrow.style.opacity = '1';
					} else {
						elemArrow.style.opacity = '0';
					}
				}
			}
		};

		scrollManagment();

		document.getElementById('landingPage-container')?.addEventListener('scroll', (e: Event) => scrollManagment(e));
	}, []);

	const goNext = () => {
		console.log('↳ Start playing');

		if (props.next) {
			props.next();
		}

		let languageElem = document.getElementById('landingPage');
		if (languageElem) {
			languageElem.style.opacity = '0';

			let homeElem = document.getElementsByClassName('home');
			if (homeElem) {
				for (let elem of homeElem) {
					(elem as HTMLElement).style.pointerEvents = 'none';
				}
			}
		}
	};

	const shareData = {
		title: 'Laennec',
		url: 'https://laennec.timescope.com/',
	};

	return (
		<React.Fragment>
			<div className='landingPage'>
				<div
					className='landingPage-click clickable '
					style={{
						position: 'absolute',
						top: '45%',
						height: '25%',
						width: '100%',
						zIndex: 81000,
					}}
					onClick={async () => {
						try {
							await navigator.share(shareData);
						} catch (err) {}
					}}
				></div>
				<video
					className='landingPage-video'
					autoPlay
					controls={false}
					playsInline
					muted
					crossOrigin='anonymous'
					loop
					src={language === 'fr' ? './assets/videos/end_FR.mp4' : './assets/videos/end_EN.mp4'}
				></video>
			</div>
		</React.Fragment>
	);
};
