import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
// Icons
import speaker from '../../style/assets/icons/speaker.png';
import speakerOff from '../../style/assets/icons/speakerOff.png';
import copyright from '../../style/assets/icons/copyright.png';
import subtitles from '../../style/assets/icons/subtitles.png';
import subtitlesOff from '../../style/assets/icons/subtitlesOff.png';

export const Menu = (props: any) => {
	useEffect(() => {}, [props.sound]);

	return (
		<React.Fragment>
			<div
				className='clickable'
				style={{
					position: 'absolute',
					top: '20px',
					right: '20px',
					width: '25px',
					height: '25px',
					zIndex: 20000,
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center',
					flexDirection: 'column',
					backgroundImage: props.sound ? `url(${speaker})` : `url(${speakerOff})`,
					backgroundSize: 'cover',
					backgroundRepeat: 'no-repeat',
					backgroundPosition: 'center',
				}}
				onClick={() => {
					props.toggleSound();
				}}
			></div>
		</React.Fragment>
	);
};
