// React
import React from 'react';
import Select, { components } from 'react-select';
// Redux
import { Dispatch } from 'redux';
import { useDispatch, useSelector } from 'react-redux';
import { setLanguage } from '../../actions/user';
// Components
import { Menu } from './Menu';
// Styles
import './intro.css';
// Assets
import frFlag from '../../style/assets/language/flags/fr.png';
import enFlag from '../../style/assets/language/flags/en.png';
import cnFlag from '../../style/assets/language/flags/cn.png';
import arrow from '../../style/assets/landingPage/selectArrow.png';

export const Intro = (props: any) => {
	const language: string = useSelector((state: any) => state.user.language);
	const dispatch: Dispatch<any> = useDispatch();

	const options = [
		{ value: 'fr', label: 'Français', image: frFlag },
		{ value: 'en', label: 'English', image: enFlag },
		{ value: 'cn', label: 'Chinese (中文)', image: cnFlag },
	];

	const changeLang = (lang: string) => {
		console.log('↳ Change lang: ', lang);
		dispatch(setLanguage(lang));
	};

	const DropdownIndicator = (props: any /*ElementConfig<typeof components.DropdownIndicator>*/) => {
		return (
			<components.DropdownIndicator {...props}>
				<img style={{width: '8px', height: '8px'}} src={arrow} />
			</components.DropdownIndicator>
		);
	};

	return (
		<React.Fragment>
			<Menu
				sound={props.sound}
				toggleSound={props.toggleSound}
			/>

			<div className='intro'>
				<div
					id='btnStart'
					className='intro-click clickable btnStart '
					onClick={() => {
						if (props.next) {
							props.next();
						}
					}}
				></div>
				<Select
					id={'language-langSelection'}
					components={{ DropdownIndicator }}
					className={'language-langSelection'}
					classNamePrefix={'language-langSelection'}
					defaultValue={options[0]}
					value={options.filter((e) => e.value === language)[0]}
					options={options}
					isSearchable={false}
					hideSelectedOptions={true}
					formatOptionLabel={(country) => (
						<div className={'language-langOption '}>
							<div
								className='language-langFlag '
								style={{
									backgroundImage: 'url(' + country.image + ')',
									backgroundSize: 'contain',
									backgroundRepeat: 'no-repeat',
									backgroundPosition: 'center',
								}}
							></div>
							<div style={{fontFamily: 'Arial Bold !important'}} className={'language-langLang '}>{country.label}</div>
						</div>
					)}
					onChange={(e) => {
						if (e) {
							changeLang(e.value);
						}
					}}
					menuPlacement={'auto'}
				/>
				<video
					className='intro-video'
					autoPlay
					controls={false}
					playsInline
					muted
					crossOrigin='anonymous'
					loop
					src={language === 'fr' ? './assets/videos/intro_FR.mp4' : './assets/videos/intro_EN.mp4'}
				></video>
			</div>
		</React.Fragment>
	);
};
