// React
import { useEffect, useState } from 'react';
import { LandingPage } from '../landingPage/LandingPage';
// Components
import { Menu } from './Menu';
import { Loader } from './Loader';
import { Sphere360 } from '../sphere360/Sphere360';
import { useSelector } from 'react-redux';
import { Intro } from '../intro/Intro';

export const Home = (props: any) => {
	const language: string = useSelector((state: any) => state.user.language);

	const [videoName, setVideoName] = useState('KERING_EN');
	const [maxSize, setMaxSize] = useState(8192);

	// Sound
	const [sound, setSound] = useState(true);

	const toggleSound = () => {
		console.log('Toggle Sound Trigger');
		let videoElement = document.getElementById('video');

		if (videoElement) {
			(videoElement as HTMLVideoElement).muted = !(videoElement as HTMLVideoElement).muted;
			setSound(!sound);
		}
	};

	// Subtitles
	// const [subtitles, setSubtitles] = useState(false);

	// const toggleSubtitles = () => {
	// 	console.log('Toggle Subtitles Trigger');
	// 	let srtElem = document.getElementById('srt');

	// 	if (srtElem) {
	// 		srtElem.style.opacity = subtitles ? '0' : '1';
	// 	}
	// 	setSubtitles(!subtitles);
	// };

	// Pages display
	const [introPage, displayIntroPage] = useState(true);
	const [landingPage, setLandingPage] = useState(false);

	// Loader
	const [buffered, setBuffered] = useState(0);
	const [pausedSince, setPausedSince] = useState(50);
	const [loader, setLoader] = useState(false);

	useEffect(() => {}, [buffered]);

	useEffect(() => {
		let inter = setInterval(() => {
			if (pausedSince > 0) {
				if (Date.now() - pausedSince > 1000) {
					setLoader(true);
					return;
				}
			}
			setLoader(false);
		}, 1000);

		return () => clearInterval(inter);
	});

	useEffect(() => {}, [pausedSince]);

	const introDisapear = () => {
		let videoElement = document.getElementById('video');
		if (videoElement) {
			setTimeout(() => {
				setPausedSince(Date.now());
				(videoElement as HTMLVideoElement).play();

				// if (subtitles) {
				// 	let srtElem = document.getElementById('srt');

				// 	if (srtElem) {
				// 		srtElem.style.opacity = '1';
				// 	}
				// }
			}, 900);

			let homeElement = document.getElementById('home');

			if (homeElement) {
				homeElement.style.pointerEvents = 'none';
			}

			setTimeout(() => {
				let elem = document.getElementById('viewport');

				if (elem) {
					elem.style.opacity = '1';
				}
			}, 500);
		} else {
			alert('video not ok');
		}

		setTimeout(() => {
			displayIntroPage(false);
		}, 500);
	};

	const newName = (lang: string) => {
		let name = 'KERING';

		switch (lang) {
			case 'fr':
				name += '_FR';
				break;
			case 'en':
				name += '_EN';
				break;
			case 'cn':
				name += '_CN';
				break;
			default:
				name += '_EN';
		}

		// if (maxSize <= 2880) {
		// 	name += '_2K';
		// } /*if (maxSize <= 4096)*/ else {
		// 	name += '_3K';
		// } /*else {
		// 		name += '_4K';
		// 	} /*else {
		// 		name += '_5K';
		// 	}*/
		console.log('VideoName =', name);
		setVideoName(name);
	};

	useEffect(() => {
		newName(language);
	}, [language]);

	return (
		<div style={{ width: '100%', height: '100%' }}>
			{/* THREE Instance */}
			<div className='viewport'>{<Sphere360 setMaxSize={setMaxSize} />}</div>
			{!introPage && !landingPage && <Menu sound={sound} toggleSound={toggleSound} />}
			<div id='home' className='home'>
				{/* Video hide in background */}
				<video
					id='video'
					playsInline
					preload='metadata'
					width='0'
					height='0'
					src={'./assets/videos/' + videoName + '.mp4'}
					style={{
						position: 'absolute',
						opacity: 0,
						margin: 0,
						padding: 0,
					}}
					onEnded={(e) => {
						let viewportElement = document.getElementById('viewport');
						if (viewportElement) {
							viewportElement.style.width = '0%';
							viewportElement.style.height = '0%';
							viewportElement.style.opacity = '0';
						}

						setLandingPage(true);
					}}

					onCanPlay={(e) => {
						if (!introPage && !!landingPage) {
							e.currentTarget.play();
						}
					}}
					onWaiting={(e) => {
						setPausedSince(Date.now());
					}}
					onPlaying={(e) => {
						setPausedSince(-1);
					}}
					onProgress={(e) => {
						let video = e.currentTarget;

						if (video) {
							if (video.buffered.length > 0) {
								var bufferedEnd = video.buffered.end(video.buffered.length - 1);
								var duration = video.duration;
								if (duration > 0) {
									let bufferedAmount = document.getElementById('buffered-amount');
									if (bufferedAmount) {
										bufferedAmount.style.width = (bufferedEnd / duration) * 100 + '%';
										setBuffered(Math.trunc((bufferedEnd / duration) * 100));
									}
								}
							}
						}
					}}
					onTimeUpdate={(e) => {
						let video = e.currentTarget;

						if (video) {
							var duration = video.duration;
							if (duration > 0) {
								let progressAmount = document.getElementById('progress-amount');
								if (progressAmount) {
									progressAmount.style.width = (video.currentTime / duration) * 100 + '%';
								}
								let cursor = document.getElementById('cursor');
								if (cursor) {
									cursor.style.left = 'calc('+(video.currentTime / duration) * 100 + '% - '+(video.currentTime / duration) * 20+'px )';
								}
							}
						}
					}}
				></video>

				{/* Intro Page */}
				{introPage && (
					<Intro
						sound={sound}
						toggleSound={toggleSound}
						// subtitles={subtitles}
						// toggleSubtitles={toggleSubtitles}
						next={() => introDisapear()}
					/>
				)}

				{/* Landing Page */}
				{landingPage && <LandingPage />}
			</div>

			{!landingPage && !introPage && (
				<div className='videoInterface' style={{ pointerEvents: 'none' }}>
					<div className='srt'>
						<div
							id='srt'
							style={{
								opacity: '0',
							}}
						></div>
					</div>
					<div
						id='progressbar'
						className='progressbar'
						onClick={(e) => {
							console.log('clickend');
							let progressbar = document.getElementById('progressbar');

							if (progressbar) {
								var offset = progressbar.getClientRects()[0];
								let dist = e.clientX - offset.left - 10;
								let percentage = (dist * 100) / offset.width;

								let videoElement = document.getElementById('video');
								if (videoElement) {
									(videoElement as HTMLVideoElement).currentTime = (percentage / 100) * (videoElement as HTMLVideoElement).duration;
								}

								console.log('click =', percentage);
							}
						}}
						onTouchEnd={(e) => {
							console.log('touchend');
							let progressbar = document.getElementById('progressbar');

							if (progressbar) {
								var offset = progressbar.getClientRects()[0];
								let dist = e.changedTouches[0].clientX - offset.left - 10;
								let percentage = (dist * 100) / offset.width;

								let videoElement = document.getElementById('video');
								if (videoElement) {
									(videoElement as HTMLVideoElement).currentTime = (percentage / 100) * (videoElement as HTMLVideoElement).duration;
								}

								console.log('click =', percentage);
							}
						}}
					>
						<div
							className='buffered'
							style={{
								height: '8px',
								position: 'absolute',
								background: '#555',
								width: 'calc(100% - 20px)',
								left: '10px',
								bottom: '10px',
								opacity: '0.4',
							}}
						>
							<span
								id='buffered-amount'
								style={{
									display: 'block',
									height: '100%',
									backgroundColor: '#888',
									width: '0',
								}}
							></span>
						</div>
						<div
							className='progress'
							style={{
								height: '8px',
								position: 'absolute',
								width: 'calc(100% - 20px)',
								left: '10px',
								bottom: '10px',
								opacity: '0.3',
							}}
						>
							<span
								id='progress-amount'
								style={{
									display: 'block',
									height: '100%',
									backgroundColor: '#595',
									width: '0',
								}}
							></span>
						</div>
						<div
							id='cursor'
							className='cursor'
							style={{
								height: '12px',
								position: 'absolute',
								width: '12px',
								left: '8px',
								bottom: '6px',
								opacity: '1',
								backgroundColor: '#6f8274',
								borderRadius: '6px',
								border: '2px solid white',
							}}
						></div>
					</div>
				</div>
			)}

			{loader && !introPage && !landingPage && <Loader buffered={buffered} />}
		</div>
	);
};
